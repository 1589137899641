import './App.css';
import { Home } from './screens/Home/Home';

function App() {
  return (
    <>
      <Home />
    </>
  );
}

export default App;
