import * as React from "react"

function TwitterLogo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={31.999}
      viewBox="0 0 32 31.999"
      {...props}
    >
      <path
        className="social-media-svg-icon"
        data-name="Sustracci\xF3n 2"
        d="M16 32a15.9 15.9 0 01-11.314-4.686A15.9 15.9 0 010 16 15.894 15.894 0 014.686 4.687 15.9 15.9 0 0116 0a15.9 15.9 0 0111.314 4.686A15.894 15.894 0 0132 16a15.9 15.9 0 01-4.686 11.314A15.9 15.9 0 0116 32zM3.334 25.409a15.132 15.132 0 008.124 2.382 15.02 15.02 0 0015.167-15.167v-.652a11.1 11.1 0 002.708-2.814 9.712 9.712 0 01-3.034.868 5.736 5.736 0 002.383-2.929 9.947 9.947 0 01-3.356 1.3 5.675 5.675 0 00-4.007-1.735 5.315 5.315 0 00-5.311 5.306 4.026 4.026 0 00.11 1.193 15.039 15.039 0 01-6.162-1.625 14.6 14.6 0 01-4.781-3.9 4.646 4.646 0 00-.758 2.708A5.43 5.43 0 006.8 14.788a7.719 7.719 0 01-2.383-.652v.11a5.232 5.232 0 004.223 5.2l-.042.01a5.435 5.435 0 01-1.362.206 2.979 2.979 0 01-.978-.11 5.165 5.165 0 004.985 3.686 10.8 10.8 0 01-6.61 2.273 5.39 5.39 0 01-1.299-.102z"
        fill="#758ecd"
      />
    </svg>
  )
}

export default TwitterLogo
