import * as React from "react";

function GithubLogo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      {...props}
    >
      <path
        className="social-media-svg-icon"
        data-name="Trazado 7"
        d="M29.854 8.169A16.136 16.136 0 0024.031 2.2 15.357 15.357 0 0016 0a15.358 15.358 0 00-8.031 2.2 16.134 16.134 0 00-5.823 5.969A16.353 16.353 0 000 16.401a16.209 16.209 0 003.052 9.642 15.7 15.7 0 007.885 5.926.915.915 0 00.833-.149.845.845 0 00.271-.64l-.011-1.153q-.011-1.089-.011-1.9l-.479.085a5.965 5.965 0 01-1.156.075 8.6 8.6 0 01-1.448-.15 3.2 3.2 0 01-1.4-.64 2.708 2.708 0 01-.916-1.313l-.208-.491a5.362 5.362 0 00-.656-1.089 2.532 2.532 0 00-.906-.811l-.146-.107a1.545 1.545 0 01-.271-.256 1.176 1.176 0 01-.187-.3q-.063-.15.1-.246a1.322 1.322 0 01.6-.1l.417.064a3.009 3.009 0 011.031.512 3.414 3.414 0 011.01 1.11 3.735 3.735 0 001.156 1.335 2.412 2.412 0 001.364.459 5.784 5.784 0 001.187-.106 4.074 4.074 0 00.937-.321 3.5 3.5 0 011.021-2.2 13.949 13.949 0 01-2.135-.384 8.388 8.388 0 01-1.958-.833 5.644 5.644 0 01-1.677-1.431 6.943 6.943 0 01-1.094-2.242 10.912 10.912 0 01-.427-3.2 6.317 6.317 0 011.646-4.4 5.874 5.874 0 01.146-4.356 2.855 2.855 0 011.792.288 12.387 12.387 0 011.74.822q.552.341.885.577a14.691 14.691 0 018 0l.792-.512a11.1 11.1 0 011.916-.94 2.669 2.669 0 011.688-.235 5.817 5.817 0 01.166 4.356 6.318 6.318 0 011.646 4.4 11.035 11.035 0 01-.427 3.214 6.671 6.671 0 01-1.1 2.242 5.858 5.858 0 01-1.688 1.42 8.4 8.4 0 01-1.958.833 13.935 13.935 0 01-2.135.385 3.856 3.856 0 011.083 3.032v4.506a.861.861 0 00.261.64.888.888 0 00.823.149 15.7 15.7 0 007.886-5.926A16.213 16.213 0 0032 16.4a16.367 16.367 0 00-2.146-8.231z"
        fill="#758ecd"
      />
    </svg>
  )
}

export default GithubLogo
