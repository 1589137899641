import * as React from "react"
import './social-media-styles/socialmediastyles.css';

function LinkedinLogo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <circle
            data-name="Elipse 1"
            cx={16}
            cy={16}
            r={16}
            transform="translate(245 304)"
            fill="#758ecd"
          />
        </clipPath>
      </defs>
      <g
        data-name="Enmascarar grupo 1"
        transform="translate(-245 -304)"
        clipPath="url(#prefix__a)"
      >
        <path
          className="social-media-svg-icon"
          data-name="Trazado 8"
          d="M250.494 304.009a5.963 5.963 0 00-1.413.285 5.888 5.888 0 00-2.326 1.461 5.837 5.837 0 00-1.719 3.452c-.048.33-.048 21.257 0 21.588a5.861 5.861 0 001.716 3.454 5.861 5.861 0 003.455 1.716c.33.048 21.257.048 21.588 0a5.836 5.836 0 003.452-1.719 5.837 5.837 0 001.719-3.452c.048-.33.048-21.257 0-21.588a5.766 5.766 0 00-.9-2.451 5.641 5.641 0 00-.8-.981 5.847 5.847 0 00-3.369-1.729c-.23-.037-1.073-.042-10.781-.045-5.797.001-10.575.001-10.622.009zm2.544 5.421a2.441 2.441 0 011.759 1.328 2.588 2.588 0 01.083 2.059 2.55 2.55 0 01-1.268 1.306 3.324 3.324 0 01-2.564-.015 2.223 2.223 0 01-.728-.525 2.3 2.3 0 01-.605-1.078 2.152 2.152 0 01-.058-.646 2.056 2.056 0 01.06-.638 2.312 2.312 0 01.625-1.078 2.564 2.564 0 011.428-.72l.25-.037a6.269 6.269 0 011.018.043zm14.4 6.629a5.111 5.111 0 011.626.49 5.1 5.1 0 012.737 3.56c.265 1.088.283 1.476.273 6.734l-.008 3.952-2.406.007-2.406.005-.01-4.22c-.007-4.63 0-4.37-.155-4.985a2.131 2.131 0 00-1.744-1.766 3.719 3.719 0 00-1.206.04 2.322 2.322 0 00-1.073.633 2.982 2.982 0 00-.593.8 2.178 2.178 0 00-.25.956c-.015.2-.025 1.936-.025 4.435v4.11h-4.8l.005-7.242v-7.242h4.79l.005 1.018.008 1.021.133-.193a5.679 5.679 0 011.156-1.223 4.827 4.827 0 012.414-.921 10.054 10.054 0 011.532.03zm-12.695 7.507v7.242l-2.406-.005-2.409-.007-.008-7.2c0-3.962 0-7.219.008-7.237s.515-.03 2.414-.03h2.4z"
          fill="#758ecd"
        />
      </g>
    </svg>
  )
}

export default LinkedinLogo